import React from 'react';
 import { useInView } from 'react-intersection-observer';


 // Importación de logos
import kore from '../logos/kore.svg';
import microsoft from '../logos/microsoft.png';
import lumu from '../logos/lumu.png';
import fortinet from '../logos/fortinet.png';
import appgate from '../logos/appgate.png';
import axur from '../logos/axur.png';
import know from '../logos/know.png';
import llogoooooooo from '../logos/rapid11.png';
import llogooooooooo from '../logos/ibm.png';

// Array de logos invertido

const logos = [
 
 
 
  know,
  axur,
  microsoft,
  appgate,
  fortinet,

  kore,
  lumu
  // llogoooooooo,
  // llogooooooooo
].reverse();
// Componente funcional Alianzas

const Alianzas = () => {
    // Hook useInView para detectar si el componente está visible en la pantalla

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  // Renderizado del componente

  return (
    <div className="text-black py-10h-screen">
       {/* Div para el efecto de animación */}
      <div
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={{
          visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
          hidden: { opacity: 0, y: 50 },
        }}
      >
        {/* Contenedor principal */}
        <div className='bg-[#92C1DE] items-center p-6   max-w-7xl h-4/6 mx-auto rounded-2xl shadow-lg'>
                 {/* Título */}

          <h2 className="text-4xl font-bold text-center mb-10 my-14">ALIANZAS COMERCIALES</h2>
                  {/* Línea decorativa */}

          <div className='linea mt-5'></div>
{/*Contenedor de logos*/}
          <div className="flex justify-around flex-wrap">
    {/* Mapeo de logos */}        
            {logos.map((logo, idx) => (
              <div key={idx} className="px-4 flex justify-center items-center">
                <img src={logo} alt={`Logo ${idx + 1}`} className="h-16 object-contain m-10 max-w-full" style={{ maxWidth: '200px' }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

//Exportación del componente Alianzas

export default Alianzas;
