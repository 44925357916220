// Servicios.js

import React, { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import BannerServicios from "../component/BannerServicios";
import Consultoria from "../component/Consultoria";
import Ciberseguridad from "../component/Ciberseguridad";
// import Plataforma from "../component/Plataforma";
import Casos from "../component/Casos";
import InteligenciaArtificial from "../component/InteligenciaArtificial";
import Valores from "../component/Valores";

export const Servicios = () => {
  const location = useLocation();

  const scrollToSection = useCallback((sectionId, offset = 100) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const y = section.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const validSections = ['ciberseguridad', 'consultoria', 'ia', 'casos', 'valores'];
    const targetSection = location.hash.replace('#', '');
    
    if (validSections.includes(targetSection)) {
      scrollToSection(targetSection);
    }
  }, [location, scrollToSection]);

  return (
    <div className="servicios-container">
      <BannerServicios />
      
      <section id="consultoria">
        <Consultoria />
      </section>

      <section 
        id="ciberseguridad" 
        className="section-spacing"
      >
        <Ciberseguridad />
      </section>

      <section id="ia">
        <InteligenciaArtificial />
      </section>

      <section id="casos">
        <Casos />
      </section>

      <section id="valores">
        <Valores />
      </section>
    </div>
  );
}

export default Servicios;
