import React, { useRef, useEffect, useState } from 'react';
import video1 from "../videos/dscyber.mp4"; // Importa el video que quieres mostrar
import logo from '../img/logo.png'; // Importa el logo de la empresa
import './stylevideo.css'; // Importa el archivo de estilos

export const VideoPlayer = () => {
  const videoRef = useRef(null);
  const progressBarRef = useRef(null);
  const thumbRef = useRef(null);
  const [showPlayButton, setShowPlayButton] = useState(true);

  useEffect(() => {
    const video = videoRef.current;
    const progressBar = progressBarRef.current;
    const thumb = thumbRef.current;

    const handlePause = () => {
      const logoOverlay = document.querySelector('.logo-overlay');
      if (video.paused) {
        logoOverlay.style.display = 'block';
        setShowPlayButton(true);
      } else {
        logoOverlay.style.display = 'none';
        setShowPlayButton(false);
      }
    };

    const updateProgress = () => {
      const percentage = (video.currentTime / video.duration) * 100;
      progressBar.style.width = `${percentage}%`;
      thumb.style.left = `${percentage}%`;
    };

    if (video) {
      video.addEventListener('pause', handlePause);
      video.addEventListener('play', handlePause);
      video.addEventListener('timeupdate', updateProgress);
    }

    return () => {
      if (video) {
        video.removeEventListener('pause', handlePause);
        video.removeEventListener('play', handlePause);
        video.removeEventListener('timeupdate', updateProgress);
      }
    };
  }, []);

  const handlePlayClick = () => {
    const video = videoRef.current;
    if (video) {
      video.play();
    }
  };

  return (
    <div className='container-todo'>
      <h2>SEGUIMOS CRECIENDO</h2>
      
      <div className='container-video'>
        <div className="video-container">
          <video ref={videoRef} controls className="carousel-video">
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {showPlayButton && (
            <button className="play-button" onClick={handlePlayClick}>
              Haz clic para ver el video
            </button>
          )}
          <div className="custom-controls">
            <div className="progress-bar">
              <div className="progress" ref={progressBarRef}></div>
              <div className="thumb" ref={thumbRef}></div>
            </div>
          </div>
          <img src={logo} alt="Logo" className="logo-overlay" />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
