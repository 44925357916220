import React from 'react';
  import backgroundVideo from '../videos/banner-dosssee.mp4';
 import Carousel from './Carousel'; // Importa el componente de carrusel
import './styles.css'; // Importa el archivo de estilos

 
const Banner = () => {
 
  return (
    <div className="flex w-full h-screen relative">
      <video autoPlay muted playsInline className="w-full h-full object-cover absolute top-0 left-0 z-0">
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <div className="flex flex-col justify-center items-center p-10 lg:p-20 space-y-6 text-white w-full lg:w-1/2 relative z-10">
        <h1 className="animacionh text-4xl lg:text-5xl font-bold shadow-md text-center">NUESTRO ENFOQUE</h1>
        <ul>
          <li className="text-xl font-bold lg:text-4xl shadow-sm text-center animacionl">
            Ciberseguridad
          </li>
          <li className="text-xl font-bold lg:text-4xl shadow-sm text-center animacionl">
            Inteligencia Artificial
          </li>
          <li className="text-xl font-bold lg:text-4xl shadow-sm text-center animacionl">
            Consultoría Tecnológica
          </li>
          <li className="text-xl font-bold lg:text-4xl shadow-sm text-center animacionl">
              
          </li>
        </ul>
      </div>
      
      <div className="hidden lg:flex w-1/2 h-full items-center justify-center p-5 mx-8">
        <Carousel />
      </div>
    </div>
  );
};

export default Banner;
