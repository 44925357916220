import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import logo from '../logos/LOGOS DS CYBER-07.png';

export const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-blue-900 to-blue-800 text-white">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 text-center md:text-left">
          <div className="space-y-4">
            <img className='h-20 mx-auto md:mx-0 transition-transform hover:scale-105' src={logo} alt="Logo de DS Cyber"/>
            <div className="space-y-2 text-gray-300">
              <p className="hover:text-white transition-colors">Opera eficientemente</p>
              <p className="hover:text-white transition-colors">Máximiza la inversión</p>
              <p className="hover:text-white transition-colors">Reduce riesgos.</p>
            </div>
          </div>
          <div className="space-y-4">
            <h4 className="font-bold text-xl mb-4 text-blue-200">Nosotros</h4>
            <nav className="space-y-3">
              <p className="hover:text-blue-200 transition-colors cursor-pointer">La empresa</p>
              <p className="hover:text-blue-200 transition-colors cursor-pointer">Propuesta de valor</p>
            </nav>
          </div>
          <div className="space-y-4">
            <h4 className="font-bold text-xl mb-4 text-blue-200">Productos</h4>
            <nav className="space-y-3">
              <p className="hover:text-blue-200 transition-colors cursor-pointer">DSeCOps</p>
              <p className="hover:text-blue-200 transition-colors cursor-pointer">Ciberconciencia</p>
              <p className="hover:text-blue-200 transition-colors cursor-pointer">Validación de controles</p>
              <p className="hover:text-blue-200 transition-colors cursor-pointer">Protección de datos</p>
              <p className="hover:text-blue-200 transition-colors cursor-pointer">Threat Hunting</p>
              <p className="hover:text-blue-200 transition-colors cursor-pointer">Ciberinteligencia accionable</p>
            </nav>
          </div>
          <div className="space-y-4">
            <h4 className="font-bold text-xl mb-4 text-blue-200">Servicios</h4>
            <nav className="space-y-3">
              <p className="hover:text-blue-200 transition-colors cursor-pointer">Consultoría especializada</p>
              <p className="hover:text-blue-200 transition-colors cursor-pointer">Certificación</p>
              <p className="hover:text-blue-200 transition-colors cursor-pointer">Servicio de CISO</p>
              <p className="hover:text-blue-200 transition-colors cursor-pointer">Certificación ISO27001</p>
              <p className="hover:text-blue-200 transition-colors cursor-pointer">Certificación ISO22301</p>
            </nav>
          </div>
        </div>
        <div className="border-t border-blue-700/50 mt-12 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex justify-center space-x-6">
              <a href="https://www.facebook.com/dscyber.io/" 
                 target="_blank" 
                 rel="noopener noreferrer"
                 className="hover:text-blue-300 transition-colors">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a href="https://www.linkedin.com/in/ds-cyber-3abab4284/"
                 target="_blank"
                 rel="noopener noreferrer"
                 className="hover:text-blue-300 transition-colors">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
            <p className="text-gray-300 text-sm">
              Copyright © {new Date().getFullYear()} DSCyber - All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
