// Carousel.js

import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import video1 from "../img/cardd-banerrr.webm";
import video2 from "../img/cardd-banner.webm";
import video3 from "../img/cardd-bannerr.webm";
import logo from "../img/logo.png"; // Asegúrate de tener el logo de la empresa en esta ruta
import './styles.css'; // Importa el archivo de estilos

const MySwal = withReactContent(Swal);

const Carousel = () => {
  const [currentVideo, setCurrentVideo] = useState(0);
  const videos = [
     { src: video1, link: '/servicios#ciberseguridad' },
    { src: video2, link: null },
    { src: video3, link: null }
  ];

  const videoRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentVideo((prevVideo) => (prevVideo + 1) % videos.length);
    }, 13000);

    return () => clearInterval(intervalId);
  }, [videos.length]);

 

 

  return (
    <div className="carousel-container">
      <div
        className="carousel-slide"
        style={{
          transform: `translateX(-${currentVideo * 100}%)`
        }}
      >
        {videos.map((video, index) => (
          <div key={index} className="carousel-video-wrapper" style={{ flex: '0 0 100%' }}>
            {index === 0 ? (
              <div 
                style={{ display: 'block', width: '100%', height: '100%',   position: 'relative' }}
              >
                <video ref={videoRef}   muted loop playsInline className="carousel-video">
                  <source src={video.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
       
              </div>
            ) : (
              <video autoPlay muted loop playsInline className="carousel-video">
                <source src={video.src} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ))}
      </div>

      <button
        className="carousel-btn prev"
        onClick={() => setCurrentVideo((prevVideo) => (prevVideo === 0 ? videos.length - 1 : prevVideo - 1))}
      >
        {'<'}
      </button>

      <button
        className="carousel-btn next"
        onClick={() => setCurrentVideo((prevVideo) => (prevVideo + 1) % videos.length)}
      >
        {'>'}
      </button>
    </div>
  );
};

export default Carousel;
